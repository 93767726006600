import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import { AuthContext } from "../AuthContext";
import { useMsal } from "@azure/msal-react";
import { protectedResources } from "../msalConfig";
import axios from "axios";
import LinearFillBar from "../components/NewComponents/Atoms/LinearFillBar";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MaterialReactTable } from "material-react-table";
import PieGraph from "../components/NewComponents/Charts/PieGraph";

function DataObservability(props) {
  const navigate = useNavigate();
  const { getToken, token, error } = useContext(AuthContext);
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const request = {
    scopes: protectedResources.databook.scopes,
    account: account,
  };

  const [isFetching, setIsFetching] = useState(false);
  const [dataAssetHealth, setDataAssetHealth] = useState([]);
  const [businessUComplLeader, setBusinessUComplLeader] = useState([]);
  const [businessUComplWDesc, setBusinessUComplWDesc] = useState([]);
  const [filterBusinessUComplWDesc, setFilterBusinessUComplWDesc] = useState(
    []
  );
  const [domainsComplLeader, setDomainsComplLeader] = useState([]);
  const [domainsComplWDesc, setDomainsComplWDesc] = useState([]);
  const [filterDomainsComplWDesc, setFilterDoaminsComplWDesc] = useState([]);

  const [searchDesc, setSearchDesc] = useState("");
  const [searchDescDom, setSearchDescDom] = useState("");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const qualityColumns = useMemo(() => [
    {
      accessorKey: "rank",
      header: "Dataset FQN",
      // size: 80,
      // Cell: ({ cell }) =>
      //   cell.row.original.type === "Dataset" && (
      //     <BsDatabaseFillExclamation
      //       style={{
      //         height: "1.2rem",
      //         width: "1.2rem",
      //         // paddingBottom: "8px",
      //         color: "#1890ff",
      //         marginLeft: "25%",
      //       }}
      //     />
      //   ),
    },
    {
      accessorKey: "dimension",
      header: "Score",
      // size: 160,
      // Cell: ({ cell }) => (
      //   <p
      //     style={{ marginBottom: "0" }}
      //     onClick={() => {
      //       navigate(`/DatasetDetails/${cell.row.original.fqn}`);
      //       setOpenDrawer(false);
      //     }}
      //   >
      //     {cell.row.original.fqn.split(".").pop()}
      //   </p>
      // ),
    },
    {
      accessorKey: "averageGlossaryPercentage",
      header: "Batch Date",
      // size: 140,
      Cell: ({ cell }) => (
        <div>{Math.trunc(cell.row.original.averageGlossaryPercentage)} %</div>
      ),
    },
  ]);

  const metadataRankType = (val) => {
    if (val.rank > 3) {
      return "";
    } else if (val.rank === 1) {
      return "mtScore-gold";
    } else if (val.rank === 2) {
      return "mtScore-silver";
    } else if (val.rank === 3) {
      return "mtScore-bronze";
    }
  };

  function getRandomColor() {
    // var letters = '0123456789ABCDEF';
    // var color = '#';
    // for (var i = 0; i < 6; i++) {
    //   color += letters[Math.floor(Math.random() * 16)];
    // }
    // return color;

    var colors = [
      "#0369a1",
      "#7dd3fc",
      "#1d4ed8",
      "#93c5fd",
      "#3b82f6",
      "#0ea5e9",
    ];
    return colors[Math.floor(Math.random() * colors.length)];
  }

  const fetchOrganizationDataAssetHealth = (token) => {
    setIsFetching(true);
    axios
      .get(
        `https://metainsights-api.gleafink.com:443/metainsights/insights/metadata/cards`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        setDataAssetHealth(res.data);
      })
      .catch((error) => {
        console.log("MetadataInsights Error: ", error);
      });
    setIsFetching(false);
  };

  const fetchBusinessUnitsComplianceLeaderboard = (token) => {
    setIsFetching(true);
    axios
      .get(
        `https://metainsights-api.gleafink.com:443/metainsights/insights/metadata/business_unit/leaderboard`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        setBusinessUComplLeader(res.data);
      })
      .catch((error) => {
        console.log("MetadataInsights Error: ", error);
      });
    setIsFetching(false);
  };

  const fetchBusinessUnitsComplianceWDesc = (token) => {
    setIsFetching(true);
    axios
      .get(
        `https://metainsights-api.gleafink.com:443/metainsights/insights/metadata/business_unit`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        setBusinessUComplWDesc(res.data);
      })
      .catch((error) => {
        console.log("MetadataInsights Error: ", error);
      });
    setIsFetching(false);
  };

  const fetchDomainsComplianceLeaderboard = (token) => {
    setIsFetching(true);
    axios
      .get(
        `https://metainsights-api.gleafink.com:443/metainsights/insights/metadata/domain/leaderboard`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        setDomainsComplLeader(res.data);
      })
      .catch((error) => {
        console.log("MetadataInsights Error: ", error);
      });
    setIsFetching(false);
  };

  const fetchDomainsComplianceWDesc = (token) => {
    setIsFetching(true);
    axios
      .get(
        `https://metainsights-api.gleafink.com:443/metainsights/insights/metadata/domain`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        setDomainsComplWDesc(res.data);
      })
      .catch((error) => {
        console.log("MetadataInsights Error: ", error);
      });
    setIsFetching(false);
  };

  const callapiwithtoken = async (callback) => {
    (async () => await getToken())();
    if (token) {
      await callback(token);
    }
    if (error) {
      try {
        const rtoken = (await instance.acquireTokenRedirect(request))
          .accessToken;
        await callback(rtoken);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (searchDesc === "") {
      setFilterBusinessUComplWDesc(businessUComplWDesc);
    } else if (searchDesc !== "") {
      const filteredData = businessUComplWDesc.filter((colValue) =>
        colValue.dimension.toLowerCase().includes(searchDesc.toLowerCase())
      );
      setFilterBusinessUComplWDesc(filteredData);
    }
  }, [searchDesc, businessUComplWDesc]);

  useEffect(() => {
    if (searchDescDom === "") {
      setFilterDoaminsComplWDesc(domainsComplWDesc);
    } else if (searchDescDom !== "") {
      const filteredData = domainsComplWDesc.filter((colValue) =>
        colValue.dimension.toLowerCase().includes(searchDescDom.toLowerCase())
      );
      setFilterDoaminsComplWDesc(filteredData);
    }
  }, [searchDescDom, domainsComplWDesc]);

  useEffect(() => {
    callapiwithtoken(fetchOrganizationDataAssetHealth);
    callapiwithtoken(fetchBusinessUnitsComplianceLeaderboard);
    callapiwithtoken(fetchBusinessUnitsComplianceWDesc);
    callapiwithtoken(fetchDomainsComplianceLeaderboard);
    callapiwithtoken(fetchDomainsComplianceWDesc);
  }, [token, instance]);

  return (
    <div style={{ width: "100%" }}>
      {isFetching ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "6rem",
          }}
        >
          <Skeleton variant="rounded" width={"70%"} height={200} />
          <Skeleton variant="rounded" width={"70%"} height={250} />
          <Skeleton variant="rounded" width={"70%"} height={450} />
        </div>
      ) : (
        <>
          <div
            style={{
              // display: "flex",
              // alignItems: "center",
              // justifyContent: "center",
              // margin: "4rem auto",

              // border: "1px solid #d8d8d8",
              // borderRadius: "7px",
              width: "80%",
              // height: "310px",
              margin: "6rem auto",
            }}
          >
            <h3
              style={{
                textAlign: "left",
                padding: "0 2rem",
                fontWeight: "600",
              }}
            >
              Data Pulse Observability Platform
            </h3>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                gap: "1rem",
                margin: "1.8rem 0",
              }}
            >
              {dataAssetHealth.totalDatasets !== 0 && (
                <Card sx={{ p: 1, width: "24rem" }}>
                  <Typography variant="h5" component="div">
                    {dataAssetHealth.totalDatasets}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Data Assets
                  </Typography>
                </Card>
              )}
              {dataAssetHealth.averagePercentageWithDescription !== "" && (
                <Card sx={{ p: 1, width: "24rem" }}>
                  <Typography variant="h5" component="div">
                    {Math.trunc(
                      dataAssetHealth.averagePercentageWithDescription
                    )}{" "}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Jobs
                  </Typography>
                </Card>
              )}
              {dataAssetHealth.averagePercentageWithOwner !== "" && (
                <Card sx={{ p: 1, width: "24rem" }}>
                  <Typography variant="h5" component="div">
                    {Math.trunc(dataAssetHealth.averagePercentageWithOwner)} %
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Current Month Cost
                  </Typography>
                </Card>
              )}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                gap: "1rem",
                margin: "1.8rem 0",
              }}
            >
              {dataAssetHealth.totalDatasets !== 0 && (
                <Card style={{ width: 300, height: 390 }}>
                  <>
                    <p style={{ fontSize: "16px", fontWeight: "600" }}>
                      Datasets - Quality
                    </p>
                    {/* <PieGraph data={dataAssetHealth.totalDatasets} /> */}
                  </>
                </Card>
              )}
              {dataAssetHealth.averagePercentageWithDescription !== "" && (
                <Card style={{ width: 300, height: 390 }}>
                  <>
                    <p style={{ fontSize: "16px", fontWeight: "600" }}>
                      Datasets - Timeliness
                    </p>
                    {/* <PieGraph
                      data={dataAssetHealth.averagePercentageWithDescription}
                    /> */}
                  </>
                </Card>
              )}
              {dataAssetHealth.averagePercentageWithOwner !== "" && (
                <Card style={{ width: 300, height: 390 }}>
                  <>
                    <p style={{ fontSize: "16px", fontWeight: "600" }}>
                      Job - Run Status
                    </p>
                    {/* <PieGraph
                      data={dataAssetHealth.averagePercentageWithOwner}
                    /> */}
                  </>
                </Card>
              )}
              {dataAssetHealth.averagePercentageWithOwner !== "" && (
                <Card style={{ width: 300, height: 390 }}>
                  <>
                    <p style={{ fontSize: "16px", fontWeight: "600" }}>
                      Job - Execution Timeliness
                    </p>
                    {/* <PieGraph
                      data={dataAssetHealth.averagePercentageWithOwner}
                    /> */}
                  </>
                </Card>
              )}
            </div>
          </div>

          {/* Dataset Quality Details */}
          <Accordion style={{ width: "80%", margin: "2rem auto" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <h3
                style={{
                  textAlign: "left",
                  padding: "0 2rem",
                  fontWeight: "600",
                }}
              >
                Dataset Quality Details{" "}
              </h3>
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#E6F4FF" }}>
              <div
                style={{
                  margin: "2rem auto",
                  width: "100%",
                  // height: "21rem",
                  // display: "flex",
                  // gap: "1rem",
                  padding: "0",
                  // cursor: "pointer",
                }}
              >
                <MaterialReactTable
                  displayColumnDefOptions={{
                    "mrt-row-actions": {
                      muiTableHeadCellProps: {
                        align: "center",
                        sx: { padding: "0", width: "98%" },
                      },
                      muiTableBodyCellProps: {
                        sx: { padding: "0", width: "98%" },
                      },
                    },
                  }}
                  muiTablePaperProps={{
                    sx: { border: "1px solid #1890ff" },
                  }}
                  muiTableHeadCellProps={{
                    sx: {
                      paddingLeft: "0.6rem",
                    },
                  }}
                  muiTableBodyCellProps={{
                    sx: {
                      paddingLeft: "0.6rem",
                    },
                  }}
                  initialState={{
                    density: "compact",
                    pagination: { pageSize: 10, pageIndex: 0 },
                  }}
                  columns={qualityColumns}
                  data={businessUComplLeader}
                  // enableFullScreenToggle={false}
                  enableDensityToggle={false}
                  enableHiding={false}
                  // enableColumnActions={false}
                  enableColumnFilters={false}
                  enableKeyboardShortcuts={false}
                  // enablePagination={false}
                  // enableSorting={false}
                />
              </div>
            </AccordionDetails>
          </Accordion>

          {/* Dataset Timeliness Details */}
          <Accordion style={{ width: "80%", margin: "2rem auto" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <h3
                style={{
                  textAlign: "left",
                  padding: "0 2rem",
                  fontWeight: "600",
                }}
              >
                Dataset Timeliness Details{" "}
              </h3>
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#E6F4FF" }}>
              <div
                style={{
                  margin: "2rem auto",
                  width: "100%",
                  // height: "21rem",
                  // display: "flex",
                  // gap: "1rem",
                  padding: "0",
                  // cursor: "pointer",
                }}
              >
                <MaterialReactTable
                  displayColumnDefOptions={{
                    "mrt-row-actions": {
                      muiTableHeadCellProps: {
                        align: "center",
                        sx: { padding: "0", width: "98%" },
                      },
                      muiTableBodyCellProps: {
                        sx: { padding: "0", width: "98%" },
                      },
                    },
                  }}
                  muiTablePaperProps={{
                    sx: { border: "1px solid #1890ff" },
                  }}
                  muiTableHeadCellProps={{
                    sx: {
                      paddingLeft: "0.6rem",
                    },
                  }}
                  muiTableBodyCellProps={{
                    sx: {
                      paddingLeft: "0.6rem",
                    },
                  }}
                  initialState={{
                    density: "compact",
                    pagination: { pageSize: 10, pageIndex: 0 },
                  }}
                  columns={qualityColumns}
                  data={businessUComplLeader}
                  // enableFullScreenToggle={false}
                  enableDensityToggle={false}
                  enableHiding={false}
                  // enableColumnActions={false}
                  enableColumnFilters={false}
                  enableKeyboardShortcuts={false}
                  // enablePagination={false}
                  // enableSorting={false}
                />
              </div>
            </AccordionDetails>
          </Accordion>

          {/* Job Run Status */}
          <Accordion style={{ width: "80%", margin: "2rem auto" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <h3
                style={{
                  textAlign: "left",
                  padding: "0 2rem",
                  fontWeight: "600",
                }}
              >
                Job Run Status
              </h3>
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#E6F4FF" }}>
              <div
                style={{
                  margin: "2rem auto",
                  width: "100%",
                  // height: "21rem",
                  // display: "flex",
                  // gap: "1rem",
                  padding: "0",
                  // cursor: "pointer",
                }}
              >
                <MaterialReactTable
                  displayColumnDefOptions={{
                    "mrt-row-actions": {
                      muiTableHeadCellProps: {
                        align: "center",
                        sx: { padding: "0", width: "98%" },
                      },
                      muiTableBodyCellProps: {
                        sx: { padding: "0", width: "98%" },
                      },
                    },
                  }}
                  muiTablePaperProps={{
                    sx: { border: "1px solid #1890ff" },
                  }}
                  muiTableHeadCellProps={{
                    sx: {
                      paddingLeft: "0.6rem",
                    },
                  }}
                  muiTableBodyCellProps={{
                    sx: {
                      paddingLeft: "0.6rem",
                    },
                  }}
                  initialState={{
                    density: "compact",
                    pagination: { pageSize: 10, pageIndex: 0 },
                  }}
                  columns={qualityColumns}
                  data={businessUComplLeader}
                  // enableFullScreenToggle={false}
                  enableDensityToggle={false}
                  enableHiding={false}
                  // enableColumnActions={false}
                  enableColumnFilters={false}
                  enableKeyboardShortcuts={false}
                  // enablePagination={false}
                  // enableSorting={false}
                />
              </div>
            </AccordionDetails>
          </Accordion>

          {/* Job Execution Timeliness */}
          <Accordion style={{ width: "80%", margin: "2rem auto" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <h3
                style={{
                  textAlign: "left",
                  padding: "0 2rem",
                  fontWeight: "600",
                }}
              >
                Job Execution Timeliness
              </h3>
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#E6F4FF" }}>
              <div
                style={{
                  margin: "2rem auto",
                  width: "100%",
                  // height: "21rem",
                  // display: "flex",
                  // gap: "1rem",
                  padding: "0",
                  // cursor: "pointer",
                }}
              >
                <MaterialReactTable
                  displayColumnDefOptions={{
                    "mrt-row-actions": {
                      muiTableHeadCellProps: {
                        align: "center",
                        sx: { padding: "0", width: "98%" },
                      },
                      muiTableBodyCellProps: {
                        sx: { padding: "0", width: "98%" },
                      },
                    },
                  }}
                  muiTablePaperProps={{
                    sx: { border: "1px solid #1890ff" },
                  }}
                  muiTableHeadCellProps={{
                    sx: {
                      paddingLeft: "0.6rem",
                    },
                  }}
                  muiTableBodyCellProps={{
                    sx: {
                      paddingLeft: "0.6rem",
                    },
                  }}
                  initialState={{
                    density: "compact",
                    pagination: { pageSize: 10, pageIndex: 0 },
                  }}
                  columns={qualityColumns}
                  data={businessUComplLeader}
                  // enableFullScreenToggle={false}
                  enableDensityToggle={false}
                  enableHiding={false}
                  // enableColumnActions={false}
                  enableColumnFilters={false}
                  enableKeyboardShortcuts={false}
                  // enablePagination={false}
                  // enableSorting={false}
                />
              </div>
            </AccordionDetails>
          </Accordion>

          {/* Job Cost */}
          <Accordion style={{ width: "80%", margin: "2rem auto" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <h3
                style={{
                  textAlign: "left",
                  padding: "0 2rem",
                  fontWeight: "600",
                }}
              >
                Job Cost
              </h3>
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#E6F4FF" }}>
              <div
                style={{
                  margin: "2rem auto",
                  width: "100%",
                  // height: "21rem",
                  // display: "flex",
                  // gap: "1rem",
                  padding: "0",
                  // cursor: "pointer",
                }}
              >
                <MaterialReactTable
                  displayColumnDefOptions={{
                    "mrt-row-actions": {
                      muiTableHeadCellProps: {
                        align: "center",
                        sx: { padding: "0", width: "98%" },
                      },
                      muiTableBodyCellProps: {
                        sx: { padding: "0", width: "98%" },
                      },
                    },
                  }}
                  muiTablePaperProps={{
                    sx: { border: "1px solid #1890ff" },
                  }}
                  muiTableHeadCellProps={{
                    sx: {
                      paddingLeft: "0.6rem",
                    },
                  }}
                  muiTableBodyCellProps={{
                    sx: {
                      paddingLeft: "0.6rem",
                    },
                  }}
                  initialState={{
                    density: "compact",
                    pagination: { pageSize: 10, pageIndex: 0 },
                  }}
                  columns={qualityColumns}
                  data={businessUComplLeader}
                  // enableFullScreenToggle={false}
                  enableDensityToggle={false}
                  enableHiding={false}
                  // enableColumnActions={false}
                  enableColumnFilters={false}
                  enableKeyboardShortcuts={false}
                  // enablePagination={false}
                  // enableSorting={false}
                />
              </div>
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </div>
  );
}

export default DataObservability;
