import React from "react"
import PieGraph from "../Charts/PieGraph"
import { Card, Flex } from "antd"
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import { divide } from "lodash"

const DataQualityPieCard = ({
  title,
  transformPieData,
  transformPieTableData,
}) => {
  return (
    <Card style={{ width: 300, height: 390 }}>
      <p style={{ fontSize: "16px", fontWeight: "600" }}>{title}</p>
      {transformPieData ? (
        <>
          <PieGraph data={transformPieData} />
          {transformPieTableData && (
            <TableContainer component={Paper} sx={{ maxWidth: "100%" }}>
              <Table aria-label="simple table">
                <TableHead style={{ backgroundColor: "#E6F4FF" }}>
                  <TableRow>
                    <TableCell>Attribute</TableCell>
                    <TableCell>DQ %</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(transformPieTableData.metricsGroup).map(
                    ([key, value]) => (
                      <TableRow
                        key={key}
                        // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {key}
                        </TableCell>
                        <TableCell>{value}</TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "8px",
            borderRadius: "6px",
            height: "50px",
            backgroundColor: "#ffea002e",
            color: "#d7b10a",
          }}
        >
          No data available
        </div>
      )}
    </Card>
  )
}

export default DataQualityPieCard
