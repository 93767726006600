import axios from "axios"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import DynamicLineChart from "../Charts/DynamicLineChart"
import DataQualityPieCard from "./DataQualityPieCard"

// import Navbar from "@/components/Navbar/page";
// import BreadcrumbElement from "@/components/BreadcrumbElement/page";

// import { Label } from "@/components/ui/label";
// import { Separator } from "@/components/ui/separator";
// import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

function DataQuality() {
  const runs = 14 // last 14 days
  const [qualityData, setQualityData] = useState()
  const [transformPieData, setTransformPieData] = useState(null)
  const [transformPieTableData, setTransformPieTableData] = useState(null)
  const [isFetching, setIsFetching] = useState(false)
  const { datasetFQN } = useParams()
  // const {
  //   state: { componentLoading },
  //   dispatch,
  // } = useValue()

  // FETCHING HISTORICAL SCORE DATA
  useEffect(() => {
    const ourRequest = axios.CancelToken.source()
    setIsFetching(true)
    const fetchCardsData = async () => {
      try {
        let response = await axios.get(
          `https://telemetry-api.gleafink.com:443/metainsights/telemetry/metrics/quality/historical?dataset_name=${datasetFQN.toLowerCase()}&metric_key=Score&runs=14`,

          {
            cancelToken: ourRequest.token,
          }
        )
        setQualityData(response.data)
        setIsFetching(false)
      } catch (err) {
        if (axios.isCancel(err)) {
          return
        } else {
          console.error(err)
        }
      }
    }
    fetchCardsData()
    return () => {
      ourRequest.cancel()
    }
  }, [])

  // FETCH TABLE DATA
  useEffect(() => {
    const ourRequest = axios.CancelToken.source()
    const fetchCardsData = async () => {
      try {
        let response = await axios.get(
          `https://telemetry-api.gleafink.com:443/metainsights/telemetry/metrics/quality/latest/grouped?object_id=${datasetFQN.toLowerCase()}`,
          {
            cancelToken: ourRequest.token,
          }
        )
        setTransformPieTableData(
          response.data.metrics.reduce((acc, item) => {
            acc[item.metricKey] = {
              metricsGroup: item.metricsGroup,
            }
            return acc
          }, {})
        )
      } catch (err) {
        if (axios.isCancel(err)) {
          return
        } else {
          console.error(err)
        }
      }
    }
    fetchCardsData()
    return () => {
      ourRequest.cancel()
    }
  }, [])

  // FETCHING QUALITY REPORTS
  useEffect(() => {
    const ourRequest = axios.CancelToken.source()
    const fetchProfilingMetrics = async () => {
      try {
        let response = await axios.get(
          ` https://telemetry-api.gleafink.com:443/metainsights/telemetry/metrics/qualityreport?object_id=${datasetFQN.toLowerCase()}`,
          {
            cancelToken: ourRequest.token,
          }
        )

        setTransformPieData(
          response.data.metrics.reduce((acc, item) => {
            acc[item.metricKey] = [
              {
                name: item.metricKey,
                value: parseFloat(item.metricValue?.toFixed(2)),
              },
              {
                name: `not-${item.metricKey}`,
                value: 100 - item.metricValue?.toFixed(2),
              },
            ]
            return acc
          }, {})
        )
      } catch (err) {
        if (axios.isCancel(err)) {
          return
        } else {
          console.error(err)
        }
      }
    }
    fetchProfilingMetrics()
    // [{ name: item.metricKey, value: item.metricValue },
    // { name: `not-${item.metricKey}`, value: 100 - item.metricValue },]
    return () => {
      ourRequest.cancel()
    }
  }, [])

  return (
    <div
      style={{
        // height: "620px",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        // padding: "60px 20px",
        padding: "0 20px",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // paddingTop: "42px",
          // paddingBottom: "15px",
        }}
      >
        {transformPieData && transformPieTableData && (
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "32px",
              // marginTop:"7rem",
            }}
          >
            <DataQualityPieCard
              title="Completeness"
              transformPieData={transformPieData?.Completeness}
              transformPieTableData={transformPieTableData?.Completeness}
            />
            <DataQualityPieCard
              title="Compliance"
              transformPieData={transformPieData?.Compliance}
              transformPieTableData={transformPieTableData?.Compliance}
            />
            <DataQualityPieCard
              title="Uniqueness"
              transformPieData={transformPieData?.Uniqueness}
              transformPieTableData={transformPieTableData?.Uniqueness}
            />
            <DataQualityPieCard
              title="Score"
              transformPieData={transformPieData?.Score}
              // transformPieTableData={transformPieTableData?.Completeness}
            />
          </div>
        )}
      </div>
      {qualityData && qualityData.metrics.length > 0 && (
        <DynamicLineChart qualityData={qualityData} w={680} h={300} />
      )}
    </div>
  )
}

export default DataQuality
