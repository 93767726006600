import React from "react"

import {
  AddCircleOutline,
  BackupTable,
  InsertChart,
  SpaceDashboard,
} from "@mui/icons-material"
import { Handle, Position } from "reactflow"
import { IconButton, Tooltip, Typography } from "@mui/material"
import { PiCubeTransparentFill } from "react-icons/pi"
import { BsDatabaseFillExclamation } from "react-icons/bs"

const DatasetNode = ({ data, isConnectable }) => {
  const { schema, label, dataSourceName, iconType } = data

  const shortenString = (longString) => {
    if (longString.length <= 30) {
      return longString
    }

    const firstPart = longString.slice(0, 12)
    const lastPart = longString.slice(-18)

    return `${firstPart}...${lastPart}`
  }

  // const ToolTipContent = () => {
  //   return (
  //     <ul>
  //       <li>Name: {label}</li>
  //       {type === "task" ? (
  //         <li>tid: {data.tid}</li>
  //       ) : (
  //         <li>uuid: {data.uuid}</li>
  //       )}
  //     </ul>
  //   );
  // };

  return (
    <div style={{ padding: "8px 4px" }}>
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          // overflowX: "hidden",
        }}
      >
        <div>
          <span style={{ fontSize: "7.6px", color: "gray" }}>SOURCE</span>
        </div>
        <div
          style={{
            marginLeft: 6,
          }}
        >
          {iconType === "datacube" && (
            <PiCubeTransparentFill
              style={{ color: "#1890ff", fontSize: "31px" }}
              size={28}
            />
          )}
          {iconType === "Dataset" && (
            <BsDatabaseFillExclamation
              style={{ color: "#1890ff", fontSize: "31px" }}
              size={24}
            />
          )}
          {iconType === "dashboard" && (
            <SpaceDashboard
              style={{ color: "#1890ff", fontSize: "31px" }}
              size={24}
            />
          )}
          {iconType === "chart" && (
            <InsertChart
              style={{ color: "#1890ff", fontSize: "31px" }}
              size={24}
            />
          )}
          {/* <BackupTable style={{ color: "#001529", fontSize: "31px" }} /> */}
        </div>
        <div
          style={{
            marginLeft: 6,
            width: "210px",
            display: "flex",
            lineHeight: "14px",
            flexDirection: "column",
          }}
        >
          <Typography variant="p" component="p" style={{ width: 130 }}>
            <Tooltip placement="left-start" title={label} arrow>
              <span>{shortenString(label)}</span>
            </Tooltip>
          </Typography>
          <Typography
            variant="p"
            component="p"
            style={{ width: 130, fontSize: 10 }}
          >
            schema: {schema}
          </Typography>
        </div>
        <div>
          <span style={{ fontSize: "7.6px", color: "gray" }}>TARGET</span>
        </div>
      </div>
      <Handle
        type="source"
        position={Position.Right}
        isConnectable={isConnectable}
      />
    </div>
  )
}

export default DatasetNode
