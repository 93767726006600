import { useMsal } from "@azure/msal-react"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import ShieldIcon from "@mui/icons-material/Shield"
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech"
import {
  Box,
  Button,
  Divider,
  Paper,
  Skeleton,
  Snackbar,
  Tab,
  Tabs,
  Typography,
} from "@mui/material"
import axios from "axios"
import "../styles/DatasetDetails.style.css"
import _ from "lodash"
import React, { useContext, useEffect, useState } from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { useParams } from "react-router-dom"
import { AuthContext } from "../AuthContext"
import BreadcrumbMUI from "../components/NewComponents/BreadcrumbMUI"
import CustomTabPanel from "../components/NewComponents/CustomTabPanel"
import Activities from "../components/NewComponents/DatasetDetails/Activities"
import DataQuality from "../components/NewComponents/DatasetDetails/DataQuality"
import Overview from "../components/NewComponents/DatasetDetails/Overview"
import SampleData from "../components/NewComponents/DatasetDetails/SampleData"
import Schema from "../components/NewComponents/DatasetDetails/Schema"
import Utilization from "../components/NewComponents/DatasetDetails/Utilization"
// import DatasetDetailsInfoModal from "../components/NewComponents/DatasetDetailsInfoModal"
import ReusableCertifiedIcon from "../components/NewComponents/ReusableCertifiedIcon"
import { protectedResources } from "../msalConfig"
import LineageTree from "../components/NewComponents/newLineage/index"
import { DATABOOK_API, TELEMETRY_API } from "../serverConfig"
import { useValue } from "../context/ContextProvider"
import {
  generateDatasetsLineageData,
  generateOneClickLineage,
} from "../components/NewComponents/newLineage/utils/function"
import { PiCubeTransparentFill } from "react-icons/pi"
import { BsDatabaseFillExclamation } from "react-icons/bs"
import ThumbDownIcon from "@mui/icons-material/ThumbDown"
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined"
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined"
import ThumbUpIcon from "@mui/icons-material/ThumbUp"
import LikeDislikeModal from "../components/NewComponents/LikeDislikeModal"
import { InsertChart, SpaceDashboard } from "@mui/icons-material"

function DatasetDetails() {
  const { datasetFQN } = useParams()
  const { getToken, token, error } = useContext(AuthContext)
  const { instance } = useMsal()
  const account = instance.getActiveAccount()
  const request = {
    scopes: protectedResources.databook.scopes,
    account: account,
  }

  const COLUMN_API = `${DATABOOK_API}/datasets/${datasetFQN}`
  const [isFetching, setIsFetching] = useState(false)
  // const [maskedcolumns, setMaskedColumns] = useState([])
  const [modaldata, setModalData] = useState([])
  const [colsdata, setColsData] = useState([])
  const [certifiedetails, setCertifiedDetails] = useState({})
  const [oneMonthUserVisitCount, setOneMonthUserVisit] = useState("")
  const [treeDataCopy, setTreeDataCopy] = useState()
  const [dqScore, setDqScore] = useState()
  const {
    state: { treeData, lineageDepth },
    dispatch,
  } = useValue()
  // const [fqn, setFqn] = useState("")
  // const [value, setValue] = useState("")
  // const [id, setId] = useState()
  // const [sensitivity, setSensitivity] = useState([])
  // const [datasetstage, setDatasetTags] = useState([])
  // const [tagTabData, setTagTabData] = useState([])
  // const [securityclassification, setSecurityClassification] = useState([])
  // const [qualfetching, setqualFetching] = useState(false)
  // const [qualdata, setqualData] = useState([])
  // const [bardata, setBarData] = useState([])
  // const [metric, setMetric] = useState([])
  const [sensitivitytypeclass, setSensitivityTypeClass] = useState("")
  const [mtscoreClass, setMtscoreClass] = useState("")

  const [toggleloading, setToggleLoading] = useState(false)
  const [clipSnackbar, setClipSnackbar] = useState(false)

  // const [openInfoDialog, setOpenInfoDialog] = useState(false)
  const [openLikeDislikeDialog, setOpenLikeDislikeDialog] = useState("")

  const [tabValue, setTabValue] = useState(0)

  function deepCopy(obj) {
    return JSON.parse(JSON.stringify(obj))
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const getProfilers = (ana) => {
    return (
      ana &&
      ana.map((analyzer) => {
        return {
          label: analyzer.metric ? analyzer.metric : "",
          value: analyzer.metric,
        }
      })
    )
  }

  const getExpectation = (exp) => {
    return (
      exp &&
      exp.map((expectation) => {
        return {
          label: (
            <span>
              {expectation.metric ? expectation.metric : ""} -
              {expectation.operation ? expectation.operation : ""}
              {expectation.value ? expectation.value : ""}
            </span>
          ),
          value: expectation.metric,
        }
      })
    )
  }

  const truncateText = (text) => {
    return text?.length > 24 ? `${text.slice(0, 24)}....` : text
  }

  // const addQualityIdex = ({ qualityIndex }) => {
  //   setBarData(qualityIndex.bardata)
  //   setMetric(qualityIndex.metricclr)
  // }

  const callapiwithtoken = async (callback) => {
    ;(async () => await getToken())()
    if (token) {
      await callback(token)
    }
    if (error) {
      try {
        const rtoken = (await instance.acquireTokenRedirect(request))
          .accessToken
        await callback(rtoken)
      } catch (error) {
        console.log(error)
      }
    }
  }

  const getallcolumndata = async (token) => {
    setIsFetching(true)
    axios
      .get(COLUMN_API, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        let maindata = res.data
        setCertifiedDetails(
          maindata.dataset?.certification !== null
            ? maindata.dataset?.certification
            : {
                certificationDate: "",
                certifiedBy: "",
                certifiedFlag: false,
              }
        )
        // checkUserEditAccess(res.data?.dataset);

        let columndata = []
        let maskingcolumns = []
        _.map(maindata.dataset["columns"], (cols) => {
          if (
            cols.colDatatype?.indexOf("double") > -1 ||
            cols.colDatatype?.indexOf("float") > -1 ||
            cols.securityClassification?.toLowerCase().indexOf("confidential") >
              -1
          ) {
            maskingcolumns.push(cols.name)
          }
          let profilesdata = getProfilers(cols.analyzers)
          return columndata.push({
            name: cols.name,
            id: cols.id,
            col_datatype: cols.colDatatype,
            description: cols.description === null ? "" : cols.description,
            descriptionApprovalStatus: cols.descriptionApprovalStatus,
            descriptionSuggestedBy: cols.descriptionSuggestedBy,
            tags: cols.tags === null ? "" : cols.tags,
            sensitivity: cols.sensitivity,
            analyzers: _.map(cols.analyzers, "metric"),
            expectations: cols.expectations,
            metric: _.map(cols.expectations, "metric"),
            dataset_id: datasetFQN,
            validationdrop: getExpectation(cols.expectations),
            profilersdrop: profilesdata,
            profilersdropdefaultValue: profilesdata ? profilesdata[0] : "",
            versionId: maindata.dataset["versionId"],
            business: maindata.dataset["business"],
            securityClassification: cols.securityClassification,
            memberType: cols.memberType,
            expression: cols.expression,
            filters: cols.filters,
          })
        })

        let modaldataa = maindata.dataset

        // const desc = modaldataa["description"]
        // const datasetdata = modaldataa["id"]
        // setMaskedColumns(maskingcolumns)
        setModalData(modaldataa)
        setColsData(columndata)
        // setFqn(modaldataa["fqn"])
        // setValue(desc)
        // setId(datasetdata)
        setIsFetching(false)
      })
  }

  // const fetchAllTags = (token) => {
  //   axios
  //     .get(`${DATABOOK_API}/tag-classification/tags`, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + token,
  //       },
  //     })
  //     .then((res) => {
  //       let result = res.data?.tags.map((item) => {
  //         if (item.fqn !== null) {
  //           return { value: item.fqn, label: item.tagName }
  //         }
  //       })
  //       setDatasetTags(result)
  //       setTagTabData(res.data?.tags)
  //     })
  // }

  // const getSecurityClassification = async (token) => {
  //   axios
  //     .get(
  //       `${DATABOOK_API}/glossary-classification/terms/classification/SecurityClassification`,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: "Bearer " + token,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       let result = res.data?.glossaryTerms.map((item) => {
  //         if (item.fqn !== null) {
  //           return { value: item.fqn, label: item.termName }
  //         }
  //       })
  //       setSecurityClassification(result)
  //     })
  // }

  // const getSelectSensitivity = (token) => {
  //   try {
  //     const fetchCarouselPromises = modaldata.securityClassification?.map(
  //       (classification) =>
  //         axios.get(
  //           `${DATABOOK_API}/glossary-classification/terms/${classification}/terms`,
  //           {
  //             headers: {
  //               "Content-Type": "application/json",
  //               Authorization: "Bearer " + token,
  //             },
  //           }
  //         )
  //     )

  //     if (fetchCarouselPromises) {
  //       Promise.all(fetchCarouselPromises).then((values) => {
  //         let container = []
  //         values.map((res) => {
  //           res.data.glossaryTerms.map((item) =>
  //             container.push({ value: item.fqn, label: item.termName })
  //           )
  //         })
  //         // setSensitivity(container)
  //       })
  //     }
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }

  // const getqualitydata = () => {
  //   const QUALITY_METRICS = `${TELEMETRY_API}metrics/quality/historical?dataset_name=${datasetFQN}&metric_key=score&runs=10`
  //   setqualFetching(true)
  //   axios.get(QUALITY_METRICS).then((res) => {
  //     let maindata = res.data
  //     let filteriddata = _.filter(
  //       maindata["metrics"],
  //       (iddata) => iddata.datasetName === `${datasetFQN}`
  //     )
  //     let qualdatas = []
  //     _.map(filteriddata, (da) => {
  //       let unixupdatedate = moment.unix(da.batchDate)
  //       return qualdatas.push({
  //         datasetName: da.datasetName,
  //         metricValue: parseFloat(da.metricValue).toFixed(2),
  //         updatedAt: unixupdatedate.format("MMM D, YYYY"),
  //         metricclr: da.metricValue > 90 ? "HIGH" : "LOW",
  //       })
  //     })

  //     const today = new Date()
  //     const date = moment(today)
  //     let todaydate = date.format("MMM D, YYYY")

  //     let qualdataspecific = _.filter(
  //       qualdatas,
  //       (qu) => qu["updatedAt"] === todaydate
  //     )
  //     let parsedqualdata = _.map(qualdataspecific, "metricValue")
  //     let metriccolor = _.map(qualdataspecific, "metricclr")

  //     // const { addQualityIdex } = props;
  //     let newobj = { bardata: parsedqualdata, metricclr: metriccolor }
  //     addQualityIdex({ qualityIndex: newobj })
  //     qualdatas.sort(
  //       (objA, objB) =>
  //         Number(new Date(objA.updatedAt)) - Number(new Date(objB.updatedAt))
  //     )
  //     setqualFetching(false)
  //     setqualData(qualdatas)
  //   })
  // }

  // FETCHING DQ SCORE
  useEffect(() => {
    const ourRequest = axios.CancelToken.source()
    const fetchCardsData = async () => {
      try {
        let response = await axios.get(
          `https://telemetry-api.gleafink.com:443/metainsights/telemetry/metrics/quality/historical?dataset_name=${datasetFQN.toLowerCase()}&metric_key=Score&runs=1`,

          {
            cancelToken: ourRequest.token,
          }
        )
        setDqScore(response.data?.metrics?.[0]?.metricValue)
      } catch (err) {
        if (axios.isCancel(err)) {
          return
        } else {
          console.error(err)
        }
      }
    }
    fetchCardsData()
    return () => {
      ourRequest.cancel()
    }
  }, [])

  const getUserVisitInPastOneMonth = (token) => {
    axios
      .get(
        TELEMETRY_API +
          "util/users/count/datasetId?datasetId=" +
          `${datasetFQN}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        setOneMonthUserVisit(res.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const putSubscribeInteraction = (token, subscribe) => {
    var requestbody = {
      datasetFqn: modaldata.fqn,
      subscribed: subscribe,
    }

    axios
      .patch(`${DATABOOK_API}/datasets/user/interaction`, requestbody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        // console.log(res.data);
        getallcolumndata(token)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const updateCertificationDetails = (toggleflag) => {
    setToggleLoading(true)
    // const inprogresstoast = toast.info(
    //   "Updating certify details.Please wait..."
    // );
    let username = account.username

    let requestbody = {
      certification: {
        certificationDate: new Date().toISOString(),
        certifiedBy: username,
        certifiedFlag: toggleflag,
      },
      id: modaldata.fqn,
    }

    ;(async () => await getToken())()
    if (token) {
      axios
        .put(`${DATABOOK_API}/datasets`, requestbody, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
        .then((res) => {
          getallcolumndata(token)
          // toast.update(inprogresstoast, {
          //   render: "Certification detail updated successfully.",
          //   type: toast.TYPE.SUCCESS,
          //   autoClose: 6000,
          //   closeButton: true,
          //   isLoading: false,
          // });
          setToggleLoading(false)
        })
        .catch((error) => {
          if (error.response?.status == 401) {
            // toast.update(inprogresstoast, {
            //   render: error.response.data,
            //   type: toast.TYPE.ERROR,
            //   autoClose: 6000,
            //   closeButton: true,
            //   isLoading: false
            // });
            getallcolumndata(token)
            setCertifiedDetails(certifiedetails)
          } else {
            // toast.update(inprogresstoast, {
            //   render: "Some went wrong while updating certification detail.",
            //   type: toast.TYPE.ERROR,
            //   autoClose: 6000,
            //   closeButton: true,
            //   isLoading: false,
            // });
          }
          setToggleLoading(false)
        })
    }
  }

  const classificationType = () => {
    let confidenceFlag = modaldata.securityClassification?.some(function (
      object
    ) {
      return object?.indexOf("confidential") > -1
    })
    let internalFlag = modaldata.securityClassification?.some(function (
      object
    ) {
      return object?.indexOf("internal") > -1
    })
    let publicFlag = modaldata.securityClassification?.some(function (object) {
      return object?.indexOf("public") > -1
    })

    if (confidenceFlag) {
      setSensitivityTypeClass("sensitivity-confidential")
    } else if (internalFlag) {
      setSensitivityTypeClass("sensitivity-internal")
    } else if (publicFlag) {
      setSensitivityTypeClass("sensitivity-public")
    } else {
      setSensitivityTypeClass("bg-white")
    }
    // setSensitivityType("#008000")
  }

  const metadataScoreType = () => {
    if (modaldata.metadataScore == null) {
      setMtscoreClass("mtScore-gold")
    } else if (modaldata.metadataScore >= 90) {
      setMtscoreClass("mtScore-gold")
    } else if (modaldata.metadataScore < 90 || modaldata.metadataScore > 70) {
      setMtscoreClass("mtScore-silver")
    } else if (modaldata.metadataScore <= 70) {
      setMtscoreClass("mtScore-bronze")
    }
    // setSensitivityType("#008000")
  }

  useEffect(() => {
    const fetchData = async () => {
      ;(async () => await getToken())()
      if (token) {
        await getallcolumndata(token)
      }
      if (error) {
        try {
          const rtoken = (await instance.acquireTokenRedirect(request))
            .accessToken
          await getallcolumndata(rtoken)
        } catch (error) {
          console.log(error)
        }
      }
    }

    fetchData()
    getUserVisitInPastOneMonth(token)
  }, [getToken, token, datasetFQN])

  // useEffect(() => {
  //   const fetchdatasets = async () => {
  //     try {
  //       const token = (await instance.acquireTokenSilent(request)).accessToken
  //       getqualitydata(token)
  //     } catch (error) {
  //       if (error) {
  //         try {
  //           const token = (await instance.acquireTokenRedirect(request))
  //             .accessToken
  //           getqualitydata(token)
  //         } catch (error) {
  //           // sweetAlertHandler({
  //           //   title: "Error while fetching the data. ",
  //           //   icon: "info",
  //           // });
  //           console.log(error)
  //         }
  //       }
  //     }
  //   }
  //   // fetchdatasets();
  //   callapiwithtoken(fetchAllTags)
  //   callapiwithtoken(getSecurityClassification)
  //   callapiwithtoken(getSelectSensitivity)
  // }, [instance, getToken, token, datasetFQN])

  useEffect(() => {
    classificationType()
    metadataScoreType()
    // getUserVisitInPastOneMonth();
  }, [modaldata])

  // FETCH ROOT LINEAGE
  useEffect(() => {
    if (!token) {
      getToken()
    }
    const ourRequest = axios.CancelToken.source()
    const fetchCardsData = async () => {
      try {
        let response = await axios.get(
          `https://metainsights-api.gleafink.com:443/metainsights/lineage/datasets/${datasetFQN.toLowerCase()}/ANY/${lineageDepth}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          },
          {
            cancelToken: ourRequest.token,
          }
        )
        // console.log(response.data, "hello")
        dispatch({
          type: "SET_TREE_LINEAGE_DATA",
          payload: generateDatasetsLineageData(response.data),
        })
      } catch (err) {
        if (axios.isCancel(err)) {
          return
        } else {
          console.error(err)
        }
      }
    }
    fetchCardsData()
    return () => {
      ourRequest.cancel()
      dispatch({ type: "CLEAR_TREE_DATA" })
    }
  }, [token, lineageDepth])

  useEffect(() => {
    console.log(treeData)
    setTreeDataCopy(deepCopy(treeData))
  }, [treeData])

  // LINEAGE NODE ON CLICK FUNCTION
  const onNodeItemClick = (type, id) => {
    if (type === "task") {
      console.log(id)
      const ourRequest = axios.CancelToken.source()
      const fetchNextLineage = async () => {
        try {
          let response = await axios.get(
            `https://metainsights-api.gleafink.com:443/metainsights/lineage/datasets/${id}/ANY/1`,
            {
              cancelToken: ourRequest.token,
              Authorization: "Bearer " + token,
            }
          )
          dispatch({
            type: "SET_TREE_LINEAGE_DATA",
            payload: generateOneClickLineage(response.data),
          })
          // console.log(response.data);
          // setLineageData(generateDatasetsLineageData(response.data));
          // console.log(lineageTreeData);
        } catch (err) {
          if (axios.isCancel(err)) {
            return
          } else {
            console.error(err)
          }
        }
      }
      fetchNextLineage()
    } else {
      console.log(id)
      const ourRequest = axios.CancelToken.source()
      const fetchNextLineage = async () => {
        try {
          let response = await axios.get(
            `https://metainsights-api.gleafink.com:443/metainsights/lineage/datasets/${id}/ANY/1`,
            {
              cancelToken: ourRequest.token,
              Authorization: "Bearer " + token,
            }
          )
          dispatch({
            type: "SET_TREE_LINEAGE_DATA",
            payload: generateOneClickLineage(response.data),
          })
        } catch (err) {
          if (axios.isCancel(err)) {
            return
          } else {
            console.error(err)
          }
        }
      }
      fetchNextLineage()
    }
  }

  return (
    <div style={{ width: "100%" }}>
      <BreadcrumbMUI
        goBackName="Search Results"
        goBack={true}
        last={datasetFQN}
      />
      <div style={{ margin: "0.4rem 2rem" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {isFetching ? (
            <Skeleton variant="rounded" width={"70%"} height={40} />
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "0.4rem",
              }}
            >
              <h1 style={{ fontSize: "24px", textAlign: "left", margin: "0" }}>
                {modaldata?.type === "datacube" && (
                  <PiCubeTransparentFill
                    style={{ color: "#1890ff", marginRight: "0.8rem" }}
                    size={28}
                  />
                )}
                {modaldata?.type === "Dataset" && (
                  <BsDatabaseFillExclamation
                    style={{ color: "#1890ff", marginRight: "0.8rem" }}
                    size={24}
                  />
                )}
                {modaldata?.type === "dashboard" && (
                  <SpaceDashboard
                    style={{ color: "#1890ff", marginRight: "0.8rem" }}
                    size={24}
                  />
                )}
                {modaldata?.type === "chart" && (
                  <InsertChart
                    style={{ color: "#1890ff", marginRight: "0.8rem" }}
                    size={24}
                  />
                )}
                {modaldata.name || "Name not available"}
              </h1>
              {certifiedetails?.certifiedFlag && (
                <ReusableCertifiedIcon certifieddata={certifiedetails} />
              )}
              {modaldata.metadataScore && (
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip id="button-tooltip">
                      <span>Metadata Score: {modaldata.metadataScore}%</span>
                    </Tooltip>
                  }
                >
                  <span className="px-2">
                    <MilitaryTechIcon className={mtscoreClass} />
                  </span>
                </OverlayTrigger>
              )}
              {modaldata.securityClassification &&
                modaldata.securityClassification.length > 0 && (
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id="button-tooltip">
                        <span>
                          Security Classification:{" "}
                          {modaldata.securityClassification
                            ?.map((item) => {
                              return item?.replace(
                                "securityclassification.",
                                ""
                              )
                            })
                            .toString()}
                        </span>
                      </Tooltip>
                    }
                  >
                    <p
                      className={
                        "dhc-header-line-lock-tag " + sensitivitytypeclass
                      }
                    >
                      {/* <MdLockOpen size={14} color="white" /> */}
                      <ShieldIcon />
                    </p>
                  </OverlayTrigger>
                )}
            </div>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <Divider orientation="vertical" flexItem />
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip id="button-tooltip">
                  <span>FQN: {modaldata.fqn}</span>
                </Tooltip>
              }
            >
              <div
                className="fqn-search-card-envelop"
                style={{ justifyContent: "flex-end", gap: "0.4rem" }}
              >
                <Typography
                  variant="h5"
                  component="div"
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  FQN:
                </Typography>
                <p style={{ fontSize: "14px", margin: "0", textAlign: "left" }}>
                  {truncateText(modaldata.fqn)}
                </p>
                {modaldata.fqn && (
                  <ContentCopyIcon
                    style={{ cursor: "pointer" }}
                    fontSize="small"
                    color="info"
                    onClick={() => {
                      window.navigator.clipboard.writeText(modaldata.fqn)
                      setClipSnackbar(true)
                    }}
                  />
                )}
              </div>
            </OverlayTrigger>

            <Divider orientation="vertical" flexItem />
            <Button
              style={{
                margin: "0",
                textTransform: "none",
                backgroundColor: "#1890ff",
              }}
              variant="contained"
              size="small"
              onClick={() => {
                modaldata?.dsInteraction?.[0]?.subscribed
                  ? putSubscribeInteraction(token, false)
                  : putSubscribeInteraction(token, true)
              }}
            >
              {modaldata?.dsInteraction?.[0]?.subscribed
                ? "Unsubscribe"
                : "Subscribe"}
            </Button>
            <Divider orientation="vertical" flexItem />
            {modaldata?.type === "datacube" && (
              <>
                <Button
                  style={{
                    margin: "0",
                    textTransform: "none",
                    backgroundColor: "#f3f3f3",
                    color: "#1890ff",
                  }}
                  variant="contained"
                  size="small"
                >
                  Explore
                </Button>
                <Divider orientation="vertical" flexItem />
              </>
            )}
            {(modaldata?.dsInteraction?.[0]?.interactionType === null || modaldata?.dsInteraction?.length === 0) && (
              <>
                <ThumbUpOutlinedIcon
                  style={{ color: "#d6d6d6", cursor: "pointer" }}
                  onClick={() => setOpenLikeDislikeDialog("like")}
                />
                <Divider orientation="vertical" flexItem />
                <ThumbDownOutlinedIcon
                  style={{ color: "#d6d6d6", cursor: "pointer" }}
                  onClick={() => setOpenLikeDislikeDialog("dislike")}
                />
              </>
            )}
            {modaldata?.dsInteraction?.[0]?.interactionType === "like" && (
              <>
                <ThumbUpIcon
                  style={{
                    color: "#d6d6d6",
                    cursor: "pointer",
                    color: "green",
                  }}
                  onClick={() => setOpenLikeDislikeDialog("like")}
                />
                <Divider orientation="vertical" flexItem />
                <ThumbDownOutlinedIcon
                  style={{ color: "#d6d6d6", cursor: "pointer" }}
                  onClick={() => setOpenLikeDislikeDialog("dislike")}
                />
              </>
            )}
            {/* <Divider orientation="vertical" flexItem /> */}
            {modaldata?.dsInteraction?.[0]?.interactionType === "dislike" && (
              <>
                <ThumbUpOutlinedIcon
                  style={{ color: "#d6d6d6", cursor: "pointer" }}
                  onClick={() => setOpenLikeDislikeDialog("like")}
                />
                <Divider orientation="vertical" flexItem />
                <ThumbDownIcon
                  style={{ color: "#d6d6d6", cursor: "pointer", color: "red" }}
                  onClick={() => setOpenLikeDislikeDialog("dislike")}
                />
              </>
            )}
            {/* <InfoOutlinedIcon
              style={{ cursor: "pointer" }}
              onClick={() => setOpenInfoDialog(true)}
            /> */}
          </div>
        </div>
        {isFetching ? (
          <Skeleton
            variant="rounded"
            width={"90%"}
            height={30}
            style={{ marginTop: "1rem" }}
          />
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              marginTop: "0.2rem",
              gap: "1rem",
              height: "2rem",
            }}
          >
            <Box
              sx={{ borderRight: 2, borderColor: "divider", paddingRight: 2 }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "0.4rem",
                }}
              >
                <Typography
                  variant="h5"
                  component="div"
                  style={{ fontSize: "14px", fontWeight: "600" }}
                >
                  Data Asset Type:
                </Typography>
                <p style={{ fontSize: "14px", margin: "0" }}>
                  {modaldata.type ? modaldata.type : "N/A"}
                </p>
              </div>
            </Box>
            <Box
              sx={{ borderRight: 2, borderColor: "divider", paddingRight: 2 }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "0.4rem",
                }}
              >
                <Typography
                  variant="h5"
                  component="div"
                  style={{ fontSize: "14px", fontWeight: "600" }}
                >
                  Dataset Schema:
                </Typography>
                <p style={{ fontSize: "14px", margin: "0" }}>
                  {modaldata?.datasetSchema?.charAt(0).toUpperCase() +
                    modaldata?.datasetSchema?.slice(1)}
                </p>
              </div>
            </Box>
            {modaldata?.type !== "datacube" && (
              <Box
                sx={{ borderRight: 2, borderColor: "divider", paddingRight: 2 }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "0.4rem",
                  }}
                >
                  <Typography
                    variant="h5"
                    component="div"
                    style={{ fontSize: "14px", fontWeight: "600" }}
                  >
                    DQ Score:
                  </Typography>
                  <p style={{ fontSize: "14px", margin: "0" }}>{dqScore}%</p>
                </div>
              </Box>
            )}
            <Box
              sx={{ borderRight: 2, borderColor: "divider", paddingRight: 2 }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "0.4rem",
                }}
              >
                <Typography
                  variant="h5"
                  component="div"
                  style={{ fontSize: "14px", fontWeight: "600" }}
                >
                  Metadata Score:
                </Typography>
                <p style={{ fontSize: "14px", margin: "0" }}>
                  {modaldata.metadataScore || "N/A"}
                  {modaldata.metadataScore && "%"}
                </p>
              </div>
            </Box>
          </div>
        )}
      </div>
      <div style={{ margin: "0.4rem 2rem" }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            BackgroundColor: "#E6F4FF",
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab label="Overview" />
            <Tab label="Schema" />
            {modaldata?.type !== "datacube" && <Tab label="Data Quality" />}
            <Tab label="Lineage" />
            {modaldata?.type !== "datacube" && <Tab label="Sample Data" />}
            <Tab label="Utilization" />
            <Tab label="Activities" />
          </Tabs>
        </Box>
        {modaldata?.type === "datacube" ? (
          <Paper elevation={3} style={{ padding: "0.4rem" }}>
            <CustomTabPanel value={tabValue} index={0}>
              <Overview
                isFetching={isFetching}
                modaldata={modaldata}
                certifiedetails={certifiedetails}
                oneMonthUserVisitCount={oneMonthUserVisitCount}
                getallcolumndata={getallcolumndata}
                updateCertificationDetails={updateCertificationDetails}
                toggleloading={toggleloading}
              />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
              <Schema
                isFetching={isFetching}
                colsData={colsdata}
                getallcolumndata={getallcolumndata}
                modaldataType={modaldata?.type}
              />
            </CustomTabPanel>
            <CustomTabPanel
              value={tabValue}
              index={2}
              className="lineage--panel"
            >
              <LineageTree
                treeData={treeDataCopy}
                onNodeItemClick={onNodeItemClick}
              />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={3}>
              <Utilization />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={4}>
              <Activities />
            </CustomTabPanel>
          </Paper>
        ) : (
          <Paper elevation={3} style={{ padding: "0.4rem" }}>
            <CustomTabPanel value={tabValue} index={0}>
              <Overview
                isFetching={isFetching}
                modaldata={modaldata}
                certifiedetails={certifiedetails}
                oneMonthUserVisitCount={oneMonthUserVisitCount}
                getallcolumndata={getallcolumndata}
                updateCertificationDetails={updateCertificationDetails}
                toggleloading={toggleloading}
              />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
              <Schema
                isFetching={isFetching}
                colsData={colsdata}
                getallcolumndata={getallcolumndata}
                modaldataType={modaldata?.type}
              />
            </CustomTabPanel>
            {/* {modaldata?.type !== "datacube" && ( */}
            <CustomTabPanel value={tabValue} index={2}>
              <DataQuality />
            </CustomTabPanel>
            {/* )} */}
            <CustomTabPanel
              value={tabValue}
              index={3}
              className="lineage--panel"
            >
              <LineageTree
                treeData={treeDataCopy}
                onNodeItemClick={onNodeItemClick}
              />
            </CustomTabPanel>
            {/* {modaldata?.type !== "datacube" && ( */}
            <CustomTabPanel value={tabValue} index={4}>
              <SampleData />
            </CustomTabPanel>
            {/* )} */}
            <CustomTabPanel value={tabValue} index={5}>
              <Utilization />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={6}>
              <Activities />
            </CustomTabPanel>
          </Paper>
        )}
      </div>
      {/* <DatasetDetailsInfoModal
        open={openInfoDialog}
        onClose={() => setOpenInfoDialog(false)}
      /> */}
      <LikeDislikeModal
        open={openLikeDislikeDialog}
        onClose={() => setOpenLikeDislikeDialog("")}
        like={openLikeDislikeDialog}
        data={modaldata}
        getallcolumndata={getallcolumndata}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        open={clipSnackbar}
        onClose={() => setClipSnackbar(false)}
        message="Text copied to clipboard"
        key={modaldata.fqn}
      />
    </div>
  )
}

export default DatasetDetails
