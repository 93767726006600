import { useMsal } from "@azure/msal-react";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import PunchClockIcon from "@mui/icons-material/PunchClock";
import AbcIcon from "@mui/icons-material/Abc";
import FunctionsIcon from "@mui/icons-material/Functions";
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  OutlinedInput,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { IoFlag, IoTextSharp } from "react-icons/io5";
import { TbNumber123, TbNumbers } from "react-icons/tb";
import { toast } from "react-toastify";
import editicon from "../../../assets/images/pencil.svg";
import { AuthContext } from "../../../AuthContext";
import { protectedResources } from "../../../msalConfig";
import { DATABOOK_API } from "../../../serverConfig";
import DDSchemaClasSensEditModal from "../DDSchemaClasSensEditModal";
import DDSchemaDescEditModal from "../DDSchemaDescEditModal";
import DDSchemaTagsEditModal from "../DDSchemaTagsEditModal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import DescSuggestedBy from "../../../assets/images/description-suggestion.jpeg";

function Schema({ isFetching, colsData, getallcolumndata, modaldataType }) {
  const { getToken, token, error } = useContext(AuthContext);
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const request = {
    scopes: protectedResources.databook.scopes,
    account: account,
  };

  const [rowData, setRowData] = useState([]);
  const [showDescEdit, setShowDescEdit] = useState("");
  const [descEditModal, setDescEditModal] = useState("");

  const [showClassiEdit, setShowClassiEdit] = useState("");
  const [classiEditModal, setClassiEditModal] = useState(false);

  const [showTagsEdit, setShowTagsEdit] = useState("");
  const [tagsEditModal, setTagsEditModal] = useState("");
  const [selectedrow, setSelectedRow] = useState({});
  const [selectedClassification, setSelectedClassification] = useState("");
  const [selectedSensitivity, setSelectedSensitivity] = useState([]);
  const [rsClassificationList, seRSClassificationList] = useState([]);
  const [rsSelectedClassification, seSelectedRSClassification] = useState("");
  const [rsSensitivityList, seRSSensitivityList] = useState([]);
  const [rsSelectedSensitivity, seSelectedRSSensitivity] = useState([]);
  var columnfieldvalue = "";

  const [searchSchema, setSearchSchema] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const callapiwithtoken = async (callback) => {
    (async () => await getToken())();
    if (token) {
      await callback(token);
    }
    if (error) {
      try {
        const rtoken = (await instance.acquireTokenRedirect(request))
          .accessToken;
        await callback(rtoken);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const modalHandler = (data, e, name) => {
    switch (name) {
      case "description":
        columnfieldvalue = e?.target?.value.trim();
        break;
      case "securityClassification":
        columnfieldvalue = data
          .filter(function (item) {
            return e?.indexOf(item.id) !== -1;
          })
          .map((item) => item.fqn)[0]
          ?.trim();
        setSelectedClassification(columnfieldvalue);
        getSensitivityForClassification(columnfieldvalue);
        setSelectedSensitivity([]);
        break;
      case "sensitivity":
        columnfieldvalue = data
          .filter(function (item) {
            return e?.indexOf(item.id) !== -1;
          })
          .map((item) => item.fqn);
        setSelectedSensitivity(columnfieldvalue);
        break;
      default:
        columnfieldvalue = "";
    }
  };

  const getClassificationSensitivityToken = (token, classificationId) => {
    axios
      .get(
        `${DATABOOK_API}/glossary-classification/terms/${classificationId}/terms`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        // let topleveldata = res.data?.glossaryTerms.map((item) => {
        //   return {
        //     id: 1,
        //     pId: 0,
        //     value: "1",
        //     val: item.classificationName,
        //     title: item.termName,
        //     termName: item.termName,
        //     fqn: item.fqn,
        //     description: item?.description,
        //     isLeaf: false,
        //   };
        // });
        seRSSensitivityList(
          res.data?.glossaryTerms.map((item) => ({
            value: item.fqn,
            label: item.termName,
          }))
        );
        // setSensitivity(topleveldata)
      });
  };

  const rsclassificationHandler = (classification) => {
    seSelectedRSClassification(classification);
    if (classification === null) {
      seSelectedRSClassification("");
    }
    if (classification) {
      getSensitivityForClassification(classification.value);
    }
    seSelectedRSSensitivity([]);
  };

  const rsSensitivityHandler = (sensitivity) => {
    seSelectedRSSensitivity(sensitivity);
  };

  const openModal = async (rowdata) => {
    if (rowdata?.securityClassification) {
      let defaultClassi = rsClassificationList.find(
        (item) => item.value === rowdata?.securityClassification
      );
      seSelectedRSClassification(defaultClassi);
    }
    if (rowdata?.sensitivity && rowdata?.sensitivity?.length > 0) {
      await axios
        .get(
          `${DATABOOK_API}/glossary-classification/terms/${rowdata?.securityClassification}/terms`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          }
        )
        .then((res) => {
          let selectSensitivity = res?.data?.glossaryTerms.find(
            (x) => x.fqn === rowdata?.sensitivity
          );
          seRSSensitivityList(
            res?.data?.glossaryTerms.map((item) => ({
              value: item.fqn,
              label: item.termName,
            }))
          );
          const result = res?.data?.glossaryTerms.filter((c) =>
            rowdata?.sensitivity.some((s) => s === c.fqn)
          );
          seSelectedRSSensitivity(
            result.map((item) => ({ value: item.fqn, label: item.termName }))
          );
        });
      setSelectedSensitivity(rowdata.sensitivity);
    }

    setClassiEditModal(true);
    setSelectedRow({ columnid: "securityClassification", rowdata: rowdata });
  };

  const getSecurityClassification = async (token) => {
    const classificationresult = await axios.get(
      `${DATABOOK_API}/glossary-classification`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );

    axios
      .get(
        // `${DATABOOK_API}/glossary-classification/terms/classification/${classificationresult?.data?.glossaryClassifications[0]?.classificationName}`,
        `${DATABOOK_API}/glossary-classification/terms/classification/SecurityClassification`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        // let topleveldata = res.data?.glossaryTerms.map((item) => {
        //   return {
        //     id: 1,
        //     pId: 0,
        //     value: "1",
        //     val: item.classificationName,
        //     title: item.termName,
        //     termName: item.termName,
        //     fqn: item.fqn,
        //     description: item?.description,
        //     isLeaf: true,
        //   };
        // });
        seRSClassificationList(
          res.data?.glossaryTerms.map((item) => ({
            value: item.fqn,
            label: item.termName,
          }))
        );
        // setClassification(topleveldata)
      });
  };

  const getSensitivityForClassification = async (classificationId) => {
    (async () => await getToken())();
    if (token) {
      await getClassificationSensitivityToken(token, classificationId);
    }
    if (error) {
      try {
        const rtoken = (await instance.acquireTokenRedirect(request))
          .accessToken;
        await getClassificationSensitivityToken(rtoken, classificationId);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const validateFields = () => {
    return rsSelectedClassification?.value?.indexOf("confidential") > -1;
  };

  const columnFielsdSave = (token) => {
    if (selectedrow?.columnid !== "description") {
      if (validateFields() && rsSelectedSensitivity?.length === 0) {
        toast.info("Security classificaton or sensitivity cannot be blank. ", {
          position: "top-right",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
    }

    // handleDataTypeForEmptyValue(selectedrow?.columnid);
    const inprogresstoast = toast.info("Updating description. Please wait...");
    let requestbody = "";
    if (selectedrow?.columnid === "description") {
      requestbody = {
        id: selectedrow?.rowdata.dataset_id,
        columns: [
          {
            name: selectedrow?.rowdata.name,
            description: columnfieldvalue,
          },
        ],
      };
    } else {
      requestbody = {
        id: selectedrow?.rowdata.dataset_id,
        columns: [
          {
            name: selectedrow?.rowdata.name,
            securityClassification:
              rsSelectedClassification?.value === undefined
                ? ""
                : rsSelectedClassification?.value,
            sensitivity: rsSelectedSensitivity?.map((item) => item.value),
          },
        ],
      };
    }

    axios
      .put(`${DATABOOK_API}/datasets/dataset-columns`, requestbody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        getallcolumndata(token);
        toast.update(inprogresstoast, {
          render: "Column field updated successfully.",
          type: toast.TYPE.SUCCESS,
          autoClose: 6000,
          closeButton: true,
          isLoading: false,
        });
        closeModal();
      })
      .catch((error) => {
        // if (error.response.status == 401) {
        //   toast.update(inprogresstoast, {
        //     render: error.response.data,
        //     type: toast.TYPE.ERROR,
        //     autoClose: 6000,
        //     closeButton: true,
        //     isLoading: false
        //   });
        //   closeModal();
        // } else {
        //   toast.update(inprogresstoast, {
        //     render: "Some went wrong while updating columns field.",
        //     type: toast.TYPE.ERROR,
        //     autoClose: 6000,
        //     closeButton: true,
        //     isLoading: false
        //   });
        // }
        console.log("dataset-columns error", error);
      });
    getallcolumndata(token);
    closeModal();
  };

  const closeModal = () => {
    seSelectedRSClassification("");
    seSelectedRSSensitivity([]);
    setClassiEditModal(false);
  };

  const classificationType = (classification) => {
    let confidenceFlag = classification?.indexOf("confidential") > -1;
    let internalFlag = classification?.indexOf("internal") > -1;
    let publicFlag = classification?.indexOf("public") > -1;

    if (confidenceFlag) {
      return "sensitivity-confidential";
    } else if (internalFlag) {
      return "sensitivity-internal";
    } else if (publicFlag) {
      return "sensitivity-public";
    } else {
      return "bg-white";
    }
  };

  useEffect(() => {
    if (searchSchema === "") {
      setRowData(colsData);
    } else if (searchSchema !== "") {
      const filteredData = colsData.filter((colValue) =>
        colValue.name.toLowerCase().includes(searchSchema.toLowerCase())
      );
      setRowData(filteredData);
    }
  }, [searchSchema, colsData]);

  useEffect(() => {
    callapiwithtoken(getSecurityClassification);
  }, []);

  return (
    <div
    // style={{ height: "56vh" }}
    >
      {isFetching ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      ) : (
        <>
          <FormControl
            sx={{
              m: 0,
              width: 390,
              display: "flex",
              justifyContent: "flex-start",
            }}
            variant="outlined"
            size="small"
          >
            <InputLabel htmlFor="outlined-adornment-search">
              Search Schema
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-search"
              type="text"
              value={searchSchema}
              onChange={(e) => setSearchSchema(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  {searchSchema !== "" && (
                    <IconButton
                      // style={{ backgroundColor: "#d8d8d8", borderRadius: "4px" }}
                      aria-label="CloseIcon"
                      onClick={() => {
                        setSearchSchema("");
                      }}
                      edge="end"
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              }
              label="Search Schema"
            />
          </FormControl>
          <TableContainer
            component={Paper}
            sx={{
              marginTop: "0.7rem",
              minWidth: 650,
              maxHeight: "54vh",
              overflow: "auto",
            }}
          >
            <Table stickyHeader aria-label="simple table">
              <TableHead sx={{ backgroundColor: "#d3d3d3" }}>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Column Datatype</TableCell>
                  {modaldataType === "datacube" && <TableCell>Title</TableCell>}
                  <TableCell>Description</TableCell>
                  {modaldataType === "datacube" && (
                    <TableCell>Member Type</TableCell>
                  )}
                  {modaldataType === "datacube" && (
                    <TableCell>Formula</TableCell>
                  )}
                  {modaldataType === "datacube" && (
                    <TableCell>Filter</TableCell>
                  )}
                  <TableCell>Tags</TableCell>
                  <TableCell>Classification</TableCell>
                  <TableCell>Sensitivity</TableCell>
                  {modaldataType !== "datacube" && (
                    <TableCell>Business</TableCell>
                  )}
                  {modaldataType !== "datacube" && (
                    <TableCell align="right">Version ID</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {rowData?.map((rowValue) => (
                  <TableRow
                    hover
                    key={rowValue.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {rowValue.name}
                    </TableCell>
                    <TableCell>
                      {/* {rowValue.col_datatype === "timestamp" && (
                        <PunchClockIcon style={{ color: "#1890ff" }} />
                      )}
                      {rowValue.col_datatype === "int4" && (
                        <TbNumber123
                          style={{
                            color: "#1890ff",
                            height: "1.8em",
                            width: "1.8em",
                          }}
                        />
                      )}
                      {rowValue.col_datatype === "text" && (
                        <IoTextSharp
                          style={{
                            color: "#1890ff",
                            height: "1.8em",
                            width: "1.8em",
                          }}
                        />
                      )}
                      {rowValue.col_datatype === "numeric" && (
                        <TbNumbers
                          style={{
                            color: "#1890ff",
                            height: "1.8em",
                            width: "1.8em",
                          }}
                        />
                      )}
                      {rowValue.col_datatype === "Flag" && (
                        <IoFlag
                          style={{
                            color: "#1890ff",
                            height: "1.8em",
                            width: "1.8em",
                          }}
                        />
                      )}
                      {"  "} */}
                      {rowValue.col_datatype}
                    </TableCell>
                    {modaldataType === "datacube" && (
                      <TableCell>{rowValue.title}</TableCell>
                    )}
                    <TableCell
                    // onMouseEnter={() => {
                    //   setShowDescEdit(rowValue.name);
                    // }}
                    // onMouseLeave={() => {
                    //   setShowDescEdit("");
                    // }}
                    >
                      {rowValue.description} <br />
                      {/* {showDescEdit === rowValue.name && ( */}
                      {/* <EditIcon
                           style={{
                             cursor: "pointer",
                             padding: "0",
                             margin: "0",
                           }}
                           fontSize="small"
                           color="info"
                           onClick={() => {
                             setDescEditModal(rowValue);
                           }}
                         /> */}
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                          gap: "1rem",
                          // margin: "0.4rem auto 0.4rem 0.8rem",
                        }}
                      >
                        {rowValue?.descriptionApprovalStatus === "draft" && (
                          <>
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip id="button-tooltip">
                                  <p
                                    style={{
                                      margin: "0",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Suggested by:{" "}
                                    {rowValue?.descriptionSuggestedBy}
                                  </p>
                                </Tooltip>
                              }
                            >
                              <img
                                src={DescSuggestedBy}
                                alt="suggested by image"
                                style={{
                                  cursor: "pointer",
                                  height: "20px",
                                  width: "20px",
                                  backgroundColor: "#E6F4FF",
                                  borderRadius: "8px",
                                  margin: "0",
                                  opacity: "1",
                                }}
                                // onClick={() => {
                                //   if (genAIAllow) {
                                //     callapiwithtoken(getGenAIDesc);
                                //   }
                                // }}
                              />
                            </OverlayTrigger>
                            <p
                              style={{
                                margin: "0",
                                fontSize: "12px",
                                border: "2px solid #E6F4FF",
                                // backgroundColor: "#E6F4FF",
                                color: "#1890ff",
                                borderRadius: "8px",
                                padding: "4px",
                                cursor: "pointer",
                              }}
                            >
                              <CheckIcon
                                style={{
                                  margin: "0 0.4rem",
                                  height: "1rem",
                                  width: "1rem",
                                }}
                              />
                            </p>
                            <p
                              style={{
                                margin: "0",
                                fontSize: "12px",
                                border: "2px solid #E6F4FF",
                                // backgroundColor: "#E6F4FF",
                                color: "#1890ff",
                                borderRadius: "8px",
                                padding: "4px",
                                cursor: "pointer",
                              }}
                            >
                              <CloseIcon
                                style={{
                                  margin: "0 0.4rem",
                                  height: "1rem",
                                  width: "1rem",
                                }}
                              />
                            </p>
                          </>
                        )}
                        <img
                          src={editicon}
                          alt="Edit Schema Description"
                          onClick={() => {
                            setDescEditModal(rowValue);
                          }}
                          style={{
                            cursor: "pointer",
                            height: "36px",
                            width: "36px",
                            padding: "-8px",
                            margin: "-8px",
                            float: "right",
                          }}
                        />
                      </div>
                      {/* )} */}
                    </TableCell>
                    {modaldataType === "datacube" && (
                      <TableCell>
                        {rowValue.memberType == "dimension" && (
                          <>
                            <AbcIcon
                              style={{
                                color: "#1890ff",
                                height: "1.4em",
                                width: "1.4em",
                              }}
                            />
                            {rowValue.memberType}
                          </>
                        )}
                        {rowValue.memberType == "measure" && (
                          <>
                            <FunctionsIcon style={{ color: "#1890ff" }} />
                            {rowValue.memberType}
                          </>
                        )}
                      </TableCell>
                    )}
                    {modaldataType === "datacube" && (
                      <TableCell>{rowValue.expression}</TableCell>
                    )}
                    {modaldataType === "datacube" && (
                      <TableCell>{rowValue.filters}</TableCell>
                    )}
                    <TableCell
                      onMouseEnter={() => {
                        setShowTagsEdit(rowValue.name);
                      }}
                      onMouseLeave={() => {
                        setShowTagsEdit("");
                      }}
                    >
                      {rowValue.tags.length !== 0 &&
                        rowValue.tags?.map((tag) => (
                          <p className="dhc-header-message-classi m-1 ">
                            {tag.tagName}
                          </p>
                        ))}
                      {showTagsEdit === rowValue.name && (
                        <img
                          src={editicon}
                          alt="Edit Schema Tags"
                          onClick={() => {
                            setTagsEditModal(rowValue);
                          }}
                          style={{
                            cursor: "pointer",
                            height: "36px",
                            width: "36px",
                            padding: "-8px",
                            margin: "-8px",
                            float: "right",
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell
                      onMouseEnter={() => {
                        setShowClassiEdit(rowValue.name);
                      }}
                      onMouseLeave={() => {
                        setShowClassiEdit("");
                      }}
                    >
                      {rowValue.securityClassification?.includes(
                        "securityclassification."
                      ) ? (
                        <p
                          className={
                            "dhc-header-message-classi m-1 " +
                            classificationType(rowValue.securityClassification)
                          }
                        >
                          {rowValue.securityClassification?.replace(
                            "securityclassification.",
                            ""
                          )}
                        </p>
                      ) : (
                        <p
                          className={
                            "dhc-header-message-classi m-1 " +
                            classificationType(rowValue.securityClassification)
                          }
                        >
                          {rowValue.securityClassification}
                        </p>
                      )}
                      {showClassiEdit === rowValue.name && (
                        <img
                          src={editicon}
                          alt="Edit Schema Classification"
                          onClick={() => {
                            openModal(rowValue);
                          }}
                          style={{
                            cursor: "pointer",
                            height: "36px",
                            width: "36px",
                            padding: "-8px",
                            margin: "-8px",
                            float: "right",
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {rowValue.sensitivity?.map((classi, index) => (
                        <p
                          key={index}
                          className="dhc-header-message-classi m-1"
                        >
                          {classi?.includes(
                            "securityclassification.confidential."
                          ) &&
                            classi?.replace(
                              "securityclassification.confidential.",
                              ""
                            )}
                          {classi?.includes(
                            "securityclassification.internal."
                          ) &&
                            classi?.replace(
                              "securityclassification.internal.",
                              ""
                            )}
                          {classi?.includes("securityclassification.public.") &&
                            classi?.replace(
                              "securityclassification.public.",
                              ""
                            )}
                        </p>
                      ))}
                    </TableCell>
                    {modaldataType !== "datacube" && (
                      <TableCell>{rowValue.business}</TableCell>
                    )}
                    {modaldataType !== "datacube" && (
                      <TableCell align="right">{rowValue.versionId}</TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={colsData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
          <DDSchemaDescEditModal
            getallcolumndata={getallcolumndata}
            token={token}
            open={descEditModal}
            onClose={() => setDescEditModal("")}
          />
          <DDSchemaTagsEditModal
            getallcolumndata={getallcolumndata}
            // token={token}
            open={tagsEditModal}
            onClose={() => setTagsEditModal("")}
          />
          <DDSchemaClasSensEditModal
            show={classiEditModal}
            setShow={setClassiEditModal}
            row={selectedrow}
            descValue={selectedrow.rowdata?.description}
            closeModal={closeModal}
            modalHandler={modalHandler}
            updateColumnsFields={() => callapiwithtoken(columnFielsdSave)}
            // classification={classification}
            // sensitivity={sensitivity}
            // selectedClassification={selectedClassification}
            // selectedSensitivity={selectedSensitivity}
            // -----------------------------------------------
            rsClassificationList={rsClassificationList}
            rsclassificationHandler={rsclassificationHandler}
            rsSelectedClassification={rsSelectedClassification}
            rsSensitivityList={rsSensitivityList}
            rsSensitivityHandler={rsSensitivityHandler}
            rsSelectedSensitivity={rsSelectedSensitivity}
          />
        </>
      )}
    </div>
  );
}

export default Schema;
