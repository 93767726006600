import React from "react"
import { Tooltip, Typography } from "@mui/material"
import { Handle, Position } from "reactflow"
import { Hub, PublishedWithChanges } from "@mui/icons-material"
import DbtLogo from "../../../../assets/images/LineageIcons/dbt.png"
import ApacheSparkLogo from "../../../../assets/images/LineageIcons/apache_sparke.png"
import CubesLogo from "../../../../assets/images/LineageIcons/cubes.png"
import SupersetLogo from "../../../../assets/images/LineageIcons/superset.png"

const JobNode = ({ data, isConnectable }) => {
  const { type, label, iconType } = data

  const shortenString = (longString) => {
    if (longString.length <= 30) {
      return longString
    }

    const firstPart = longString.slice(0, 12)
    const lastPart = longString.slice(-18)

    return `${firstPart}...${lastPart}`
  }

  // const ToolTipContent = () => {
  //   return (
  //     <ul>
  //       <li>Name: {label}</li>
  //       {type === "task" ? (
  //         <li>tid: {data.tid}</li>
  //       ) : (
  //         <li>uuid: {data.uuid}</li>
  //       )}
  //     </ul>
  //   );
  // };

  return (
    <div style={{ padding: "8px 12px", margin: "0" }}>
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <div>
          {/* <PublishedWithChanges
            style={{ color: "#007bff", fontSize: "31px" }}
          /> */}
          {iconType === "superset" && (
            <img
              src={SupersetLogo}
              alt="SupersetLogo"
              height="16px"
              width="24px"
            />
          )}
          {iconType === "spark" && (
            <img
              src={ApacheSparkLogo}
              alt="ApacheSparkLogo"
              height="20px"
              width="20px"
            />
          )}
          {iconType === "cubejs" && (
            <img src={CubesLogo} alt="CubesLogo" height="22px" width="22px" />
          )}
          {iconType === "dbt" && (
            <img src={DbtLogo} alt="dbtLogo" height="19px" width="19px" />
          )}
        </div>
        <div style={{ marginLeft: 8 }}>
          <Typography variant="p" component="p" style={{ width: 160 }}>
            <Tooltip placement="left-start" title={label} arrow>
              <span>{shortenString(label)}</span>
            </Tooltip>
          </Typography>
        </div>
      </div>
      <Handle
        type="source"
        position={Position.Right}
        isConnectable={isConnectable}
      />
    </div>
  )
}

export default JobNode
